import "./About.css";

import PageStructure from "../../components/PageStructure/PageStructure";

function About() {
    return (
        <PageStructure>
            <h1 className="page-title">About</h1>
        </PageStructure>
    )
}

export default About;