import "./Contact.css";

import PageStructure from "../../components/PageStructure/PageStructure";

function Contact() {
    return (
        <PageStructure>
            <h1 className="page-title">Contact</h1>
            {/*<div className="contact-container">
                <div>
                    <a>asd</a>
                </div>
                <div className="contact-form">
                    <form>
                        <input />
                    </form>
                </div>
            </div>*/}
        </PageStructure>
    )
}

export default Contact;