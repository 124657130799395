import PageStructure from "../../components/PageStructure/PageStructure";

import Bento from "../../components/Bento/Bento.js";

function Projects () {
    return (
        <PageStructure>
            <h1 className="page-title">Projects</h1>
            <Bento />
        </PageStructure>
    )
}

export default Projects;